import React from 'react';
import logo1 from '../../images/partners/bybit.png';
import logo2 from '../../images/partners/shell.png';
import logo3 from '../../images/partners/binance.png';
import logo4 from '../../images/partners/alpari.png';
function PartnersComponent() {
    return (
        <div className='partners-component'>
            <h1>PARTNERS</h1>
            <section className="partners-logos">
                <img src={logo1} alt="Bybit" />
                <img src={logo3} alt="Binance" />
                <img src={logo4} alt="Alpari" />
            </section>
           
        </div>
    );
}

export default PartnersComponent;
