import React from 'react';
import { Link } from 'react-scroll'; 

import photo1 from '../../images/FoterComponent/photo1.png';
import arrow from '../../images/FoterComponent/arrowr.png';

function FoterComponent() {
    return (
        <>
            <div className='foter-component' id='contact'>
                <img src={photo1} className='back-footer-img' alt="Background" />
                <main>
                    <h1>Your key to a stable financial future </h1>
                    <p>Sign up for our newsletter</p>
                    <div className='input-foter'>
                        <input placeholder='Email address' />
                        <img src={arrow} alt="Arrow" />
                    </div>
                    <div className='footer-links'>
                        <section className='web-links'>
                            <Link to="top" smooth={true} duration={500}>Home</Link>
                            <Link to="about" smooth={true} duration={500}>Our</Link>
                            <Link to="news" smooth={true} duration={500}>News</Link>
                            <Link to="project" smooth={true} duration={500}>Project</Link>
                            <Link to="contact" smooth={true} duration={500}>Contact</Link>
                        </section>

                        <section className='social-links'>
                            <a href='#'>LinkedIn <img src={arrow} alt="Arrow" /></a>
                            <a href='#'>Facebook <img src={arrow} alt="Arrow" /></a>
                            <a href='#'>Telegram <img src={arrow} alt="Arrow" /></a>
                            <a href='#'>Instagram <img src={arrow} alt="Arrow" /></a>
                        </section>
                    </div>
                </main>
            </div>
            <span className='copyrating'>© 2024 InvestBridge Ventures Fund. All Rights Reserved.</span>
        </>
    );
}

export default FoterComponent;
