import React from 'react';
import { Link } from 'react-scroll';

import logo from '../../images/Header/HeaderLogo.png';
import dots from '../../images/Header/HeaderDots.png';

const HeaderComponent = () => {
    return (
        <header className='header-main'>
            <Link 
                to="top"
                smooth={true}
                duration={500} 
            >
                <img src={logo} alt="Logo" style={{ cursor: 'pointer' }} />
            </Link>
            <Link 
                to="contact"
                smooth={true}
                duration={500} 
            >
            <img src={dots} alt="Dots" />
            </Link>
        </header>
    );
};

export default HeaderComponent;
