import React from 'react';

import photo1 from '../../images/Lines/line1.png';
import photo2 from '../../images/Lines/line2.png';
import photo3 from '../../images/Lines/line3.png';

function LinesComponent() {
    return (
        <div className='lines-component'>
            <img src={photo3} className='line-comp-line1' />
            <img src={photo2} className='line-comp-line2' />
            <img src={photo1} className='line-comp-line3' />
        </div>
    );
}

export default LinesComponent;
