import React from 'react';
import { useInView } from 'react-intersection-observer';

function ThirdComponent() {
    const { ref: employeesRef, inView: employeesInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div className='third-component' id='about'>
            <p>InvestBridge Ventures Fund is an innovative financial fund established to provide a wide range of services in the financial sector. Our key areas include investments, asset management, and brokerage services.</p>
            <section><h1 style={{ color: "#E92F30" }}>30+ </h1> <span>Number of countries we invest</span></section>
            <section ref={employeesRef} className={employeesInView ? 'animate-on-dropdown' : 'opacity-none'}>
                <h1>50+ </h1> <span>Number of employees</span>
            </section>
            <section ref={employeesRef} className={employeesInView ? 'animate-on-godowntwosec' : 'opacity-none'}><h1 style={{ color: "#E92F30" }}>150+ </h1><span>Number of investments</span></section>
            <section ref={employeesRef} className={employeesInView ? 'animate-on-godowntrisec' : 'opacity-none'}><h1>85%</h1><span>Percentage of successful startups</span></section>
            <section><h1>$1 MILLION</h1><span>Average investment size</span></section>
        </div>
    );
}

export default ThirdComponent;
