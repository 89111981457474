import React from 'react';

function FirstComponent() {
    return (
        <div className='first-component-div'>
            <section className='second-f-fc'>
                <h1>InvestBridge<br />
                    Ventures<br />
                    Fund</h1>

            </section>
            <section className='second-s-fc'>
                <h2>Great companies come from the big dreams of determined leaders.</h2>
                <p>We invest in founders with the imagination, tenacity & collaborative spirit needed to turn big ideas into even bigger businesses.</p>
                <section>
                    <button className='second-s-fc-button'>Log in</button>
                    <button className='second-s-fc-sbutton'>Invest</button>
                </section>
            </section>

        </div >
    );
}

export default FirstComponent;
