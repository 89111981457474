import Main from "./pages/Main/Main"
import { MAIN_ROUTE, } from "./utils/consts"


export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component: Main

    },
  
]