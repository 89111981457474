import React from 'react';
import { useInView } from 'react-intersection-observer';

function SecondComponent() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div className='second-component' ref={ref}>
            <p className={inView ? 'animate-on-slidebottom' : ''}>
                We build to last when we build together. InvestBridge partners & expert operating teams pride themselves on removing your barriers to growth & helping you to seize expansion opportunities.
            </p>
            <h1 className={inView ? 'animate-on-slidetop' : ''}>
                A TEAM THAT NEVER RESTS AND ALWAYS FIGHTS YOUR CORNER
            </h1>
        </div>
    );
}

export default SecondComponent;
