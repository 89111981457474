import React from 'react';


import project1 from '../../images/projects/project1.png';
import project2 from '../../images/projects/project2.png';
function ProjectsComponent() {
    return (
        <div className='project-component' id='project'>
            <main>
                <section>
                    <h1>Project 1</h1>
                    <div className='project-div'>
                        <img src={project1} />

                        <div className='overlay'><h3>A BROKER</h3></div>
                    </div>
                </section>
                <section>
                    <h1>Project 2</h1>
                    <div className='project-div'>
                        <img src={project2} />
                        <div className='overlay'><h3>A BROKER</h3></div>
                    </div>

                </section>
            </main>
            <section className='project-last-section'>
                <h2>See More Projects</h2>
            </section>
        </div>
    );
}

export default ProjectsComponent;
