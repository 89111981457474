import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import photo1 from '../../images/news/photo1.png';
import photo2 from '../../images/news/photo2.png';
import photo3 from '../../images/news/photo1.png';

import arrowl from '../../images/news/arrowleft.png';

function NewsComponent() {
    const newsData = [
        {
            title: "We have launched a new project: a broker for trading fiat currencies",
            date: "28.07.2024",
            duration: "3 MINUTES",
            image: photo1,
            description: "Our new brokerage project provides the opportunity to trade fiat currencies to maximize your financial potential."
        },
        {
            title: "We have launched a new project: a broker for trading fiat currencies",
            date: "15.08.2024",
            duration: "5 MINUTES",
            image: photo2,
            description: "Our new brokerage project provides the opportunity to trade fiat currencies to maximize your financial potential."
        },
        {
            title: "We have launched a new project: a broker for trading fiat currencies",
            date: "05.09.2024",
            duration: "4 MINUTES",
            image: photo3,
            description: "Our new brokerage project provides the opportunity to trade fiat currencies to maximize your financial potential."
        },
        {
            title: "We have launched a new project: a broker for trading fiat currencies",
            date: "05.09.2024",
            duration: "4 MINUTES",
            image: photo3,
            description: "Our new brokerage project provides the opportunity to trade fiat currencies to maximize your financial potential."
        }
    ];

    const [sliderRef, slider] = useKeenSlider({
        mode: 'snap',
        slides: {
            perView: 2.5,
            spacing: 10,
        },
        breakpoints: {
            '(max-width: 1200px)': {
                slides: {
                    perView: 1.5,
                    spacing: 10,
                },
            },
            '(max-width: 800px)': {
                slides: {
                    perView: 1,
                    spacing: 10,
                },
            },

        },
    });

    const handlePrev = () => {
        console.log("Prev Clicked");
        if (slider && slider.current) {
            slider.current.prev();
        }
    };

    const handleNext = () => {
        console.log("Next Clicked");
        if (slider && slider.current) {
            slider.current.next();
        }
    };



    return (
        <div className="news-component" id="news">
            <h1>NEWS</h1>
            <div ref={sliderRef} className="keen-slider">
                {newsData.map((newsItem, index) => (
                    <div key={index} className="keen-slider__slide">
                        <div className="news-list">
                            <section className="news-section-one">
                                <h2>{newsItem.title}</h2>
                                <img src={newsItem.image} alt="News" />
                            </section>
                            <p>{newsItem.description}</p>
                            <section className='news-section-two'>
                                <p>{newsItem.date}</p>
                                <span>{newsItem.duration}</span>
                            </section>
                        </div>
                    </div>
                ))}
            </div>

            <div className='arrow-section' >
                <div></div>
                <div>
                    <img src={arrowl} alt="Previous" onClick={handlePrev} />
                    <img src={arrowl} alt="Next" onClick={handleNext} style={{ transform: 'rotate(180deg)' }} />
                </div>
                <h3>Read More</h3>
            </div>
        </div>
    );
}

export default NewsComponent;
